import { TABLA } from '@/utils/consts'

export default {
  async insert (Vue, formData, tabla, identificador) {
    if (tabla.idtabla === TABLA.parte_trabajo.idtabla) {
      const inserted = await Vue.$api.call(
        'operacioncrm.CrearOperacionDesdeParte', {
          values: {
            idparte_montaje: identificador,
            idtoperacioncrm: formData.toperacioncrm.idtoperacioncrm,
            observaciones: formData.observaciones,
            descripcion: formData.descripcion,
          }
        }
      )
      return await Vue.$online.operacioncrm.select(inserted.data.result)
    }
  },
}
